// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-checkout', function (Y) {
	"use strict";

	Y.CaseEditorCheckoutAddressFormView = Y.Base.create('CaseEditorCheckoutAddressFormView', Y.HandlebarsView, [], {
		template: 'case-editor-checkout-invoiceaddress',
		partials: ['case-editor-checkout-product', 'case-editor-checkout-address'],
		addressForm: null,

		_handlers : [],

		initializer: function () {
			var that = this;

			that._handlers.push(that.after('checkoutInfoChange', that.render, that));
		},

		render: function () {
			var that = this,
				address = that.get('checkoutInfo')._getAttr('address'),
				addressForm,
				container = that.get('container');


			this._render({ checkoutInfo: that.get('checkoutInfo').toJSON() });

			addressForm = new Y.Form({
				srcNode: container.one('.case-editor-checkout-edit-invoice-address-form')
			});

			addressForm.render(container.one('.case-editor-checkout-edit-invoice-address-form-container'));
			addressForm.getField('firstname').set('value', address.firstName);
			addressForm.getField('lastname').set('value', address.lastName);
			addressForm.getField('company').set('value', address.companyName);
			addressForm.getField('street').set('value', address.street);
			addressForm.getField('zip').set('value', address.postalCode);
			addressForm.getField('city').set('value', address.city);
			addressForm.getField('countrycode').set('value', address.countryCode).disable();

			container.one('#ui-firstname').insert('<span id="ui-firstname-field-error-message" class="case-editor-checkout-error-message" style="display:none">Bitte geben Sie einen Vornamen ein.</span>', 'after');
			container.one('#ui-lastname').insert('<span id="ui-lastname-field-error-message" class="case-editor-checkout-error-message" style="display:none">Bitte geben Sie einen Nachnamen ein.</span>', 'after');
			container.one('#ui-street').insert('<span id="ui-street-field-error-message" class="case-editor-checkout-error-message" style="display:none">Bitte geben Sie eine Straße ein.</span>', 'after');
			container.one('#ui-zip').insert('<span id="ui-zip-field-error-message" class="case-editor-checkout-error-message" style="display:none">Bitte geben Sie eine Postleitzahl ein.</span>', 'after');
			container.one('#ui-city').insert('<span id="ui-city-field-error-message" class="case-editor-checkout-error-message" style="display:none">Bitte geben Sie einen Ort ein.</span>', 'after');
			container.one('#ui-countrycode').insert('<span id="ui-countrycode-field-error-message" class="case-editor-checkout-error-message" style="display:none">Lediglich die Anschrift Ihres deutschen Wohnsitzes ist zulässig.</span>', 'after');

			this.addressForm = addressForm;

			that._handlers.push(container.delegate('blur', function (e) {
				that.updateErrorMessages(e.currentTarget.get('value').trim(), e.currentTarget.get('id') + "-error-message");
				that.updateButton();
			}, 'input'));
		},

		updateErrorMessages: function (input, messageId) {
			var errorMessage = this.get('container').one("#" + messageId);
			if (errorMessage) {
				if (messageId.includes('countrycode') ? input === 'Deutschland' : input) {
					errorMessage.setStyle('display', 'none');
				} else {
					errorMessage.setStyle('display', '');
				}
			}
		},

		updateButton: function () {
			var container = this.get('container');

			if (container.one('#ui-firstname-field-error-message').getStyle('display') === 'none' &&
				container.one('#ui-lastname-field-error-message').getStyle('display') === 'none' &&
				container.one('#ui-street-field-error-message').getStyle('display') === 'none' &&
				container.one('#ui-zip-field-error-message').getStyle('display') === 'none' &&
				container.one('#ui-city-field-error-message').getStyle('display') === 'none' &&
				container.one('#ui-countrycode-field-error-message').getStyle('display') === 'none') {
				container.one('#ui-update-address').removeClass('btn-disabled').removeAttribute('disabled');
			} else if (container.one('#ui-update-address .btn-disabled') === null) {
				container.one('#ui-update-address').addClass('btn-disabled').setAttribute('disabled', 'disabled');
			}
		},

		destructor: function () {
			Y.Array.each(this._handlers, function (it) {
				if (it) {
					it.detach();
					it = null;
				}
			});
		}
	}, {
		ATTRS: {
			container: {
				valueFn: function () {
					return Y.Node.create('<div id="case-editor-checkout-invoiceaddress"/>');
				}
			}
		}
	});

	Y.CaseEditorCheckoutPaymentMethodFormView = Y.Base.create('CaseEditorCheckoutPaymentMethodFormView', Y.HandlebarsView, [], {
		template: 'case-editor-checkout-paymentmethod',
		partials: ['case-editor-checkout-product'],
		paymentMethodForm: null,
		_handlers: [],

		initializer: function () {
			var that = this;

			that._handlers.push(that.after('checkoutInfoChange', that.render, that));

            //need to ensure filing state is set even after F5
            Y.io('elster/getFilingState', {
                headers: { "Accept" : "application/json" },
                on: {
					success: function (id, res) {
						Y._currentFilingState = Y.JSON.parse(res.responseText);
					}
				},
                context: this
            });
		},

		render: function () {
			var info = this.get('checkoutInfo'),
				paymentMethod,
				container = this.get('container'), paymentMethodForm;
			if (info._getAttr('payment') != null) {
				paymentMethod = info._getAttr('payment').code;
			}

			var checkout = this.get('checkoutInfo').toJSON();
			checkout.filingState = Y._currentFilingState;

			this._render({
				checkoutInfo: checkout
			});

			if (paymentMethod && checkout.filingState.paymentMethodCheckEnabled) {
                if (!Y._currentFilingState.paymentMethods.includes(paymentMethod)) {
                	console.log("Skipping preselection of payment method " + paymentMethod);

                	paymentMethod = null;
				}
			}

			paymentMethodForm = new Y.Form({
				srcNode: container.one('.case-editor-checkout-edit-payment-method-form')
			});
			paymentMethodForm.render(container.one('.case-editor-checkout-edit-payment-method-selector'));
			paymentMethodForm.getField('paymentMethod').set('value', paymentMethod);
			this.paymentMethodForm = paymentMethodForm;

			container.all('.ui-choose-payment-btn').removeClass('active');
			if (paymentMethod != null) {
				container.one('#ui-choose-payment-' + paymentMethod).addClass('active');
			}
		},

		destructor: function () {
			Y.Array.each(this._handlers, function (it) {
				if (it) {
					it.detach();
					it = null;
				}
			});
		}
	}, {
		ATTRS: {
			container: {
				valueFn: function () {
					return Y.Node.create('<div id="case-editor-checkout-paymentmethod"/>');
				}
			}
		}
	});

	Y.CaseEditorCheckoutView = Y.Base.create('CaseEditorCheckoutView', Y.HandlebarsView, [], {
		template: 'case-editor-checkout-overview',
		partials: ['case-editor-checkout-product', 'case-editor-checkout-address'],
		tokenForm: null,

		_handlers: [],

		initializer: function () {
			var that = this;

			that._handlers.push(that.after('checkoutInfoChange', that.render, that));

            // need to ensure filing state is set even after F5
            Y.io('elster/getFilingState', {
                headers: { "Accept" : "application/json" },
                on: {
					success: function (id, res) {
						Y._currentFilingState = Y.JSON.parse(res.responseText);
					}
				},
                context: this
            });
		},

		render: function () {
			var that = this,
				container = that.get('container'), tokenForm,
				info = that.get('checkoutInfo');

			this._render(info.toJSON());

			tokenForm = new Y.Form({
				srcNode: container.one('#ui-token-form')
			});
			tokenForm.render(container.one('#ui-token-form-container'));
			that.tokenForm = tokenForm;
			that.updateCheckoutButton();

			that._handlers.push(container.one('#ui-tokenCode-field').on('valuechange', function (e) {
				that.updateTokenButton(e.newVal);
			}));
			// 'change' doesn't work for IE8 -> so 'click'

			/*
			that._handlers.push(container.one('.case-editor-checkout-overview-accept-terms').delegate('click', function(e) {
				that._updateCheckboxes();
			}, 'input'));
			*/
		},

		updateCheckoutButton: function () {
			var container = this.get('container'),
				info = this.get('checkoutInfo');
			if ((info._getAttr('validPayment') || info._getAttr('product').priceCoveredByToken) && info._getAttr('validBillingAddress')) {
				container.one('#ui-start-checkout').removeClass('btn-disabled').removeAttribute('disabled');
			} else if (container.one('#ui-start-checkout .btn-disabled') === null) {
				container.one('#ui-start-checkout').addClass('btn-disabled').setAttribute('disabled', 'disabled');
			}
		},

		updateTokenButton: function (tempToken) {
			var container = this.get('container');
			if (tempToken != "") {
				container.one('#ui-update-token').removeClass('btn-disabled').removeAttribute('disabled');
			} else if (container.one('#ui-update-token .btn-disabled') === null) {
				container.one('#ui-update-token').addClass('btn-disabled').setAttribute('disabled', 'disabled');
			}
		},

		destructor: function () {
			Y.Array.each(this._handlers, function (it) {
				if (it) {
					it.detach();
					it = null;
				}
			});
		},

		ATTRS: {
			container: {
				valueFn: function () {
					return Y.Node.create('<div id="case-editor-checkout-overview"/>');
				}
			},
			checkoutInfo: {}
		}
	});

	Y.CaseEditorCheckoutPaymentView = Y.Base.create('CaseEditorCheckoutPaymentView', Y.HandlebarsView, [], {
		template : 'case-editor-checkout-payment',
		partials : ['case-editor-checkout-product'],

		_handlers : [],

		initializer: function () {
			var that = this;

			that._handlers.push(that.after('checkoutInfoChange', that.render, that));
		},

		destructor: function () {
			Y.Array.each(this._handlers, function (it) {
				if (it) {
					it.detach();
					it = null;
				}
			});
		}
	}, {
		ATTRS: {
			container: {
				valueFn: function () {
					return Y.Node.create('<div id="case-editor-checkout-payment" />');
				}
			},
			checkoutInfo: {},
			iframeUrl: {}
		}
	});

	Y.CheckoutInfo = Y.Base.create('CheckoutInfo', Y.Model, [Y.ModelSync.REST], {
		root: 'api/checkout',
		ATTRS: {
			address: {},
			alternativeAddresses: {},
			payment: {},
			product: {},
			validatedToken: {},
			validPayment: {},
			validBillingAddress: {}
		}
	});

	Y.CaseEditorAppCheckout = Y.Base.create('CaseEditorAppCheckout', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
		views: {
			addressForm: { type: 'CaseEditorCheckoutAddressFormView' },
			paymentMethodForm: { type: 'CaseEditorCheckoutPaymentMethodFormView' },
			overview: { type: 'CaseEditorCheckoutView' },
			payment: { type: 'CaseEditorCheckoutPaymentView' }
		},
		_handler : [],

		overlays: {
			'checkout-error': {
				header: "Es ist ein Fehler aufgetreten.",
				footer: '<button type="button" class="btn btn-hilited" id="ui-checkout-error-ok">OK</button>',
				template: 'case-editor-checkout-overlay-checkoutError',
				events: {
					'#ui-checkout-error-ok': { click: '_closeOverlay' },
					'.yui3-widget': { keypress: '_closeOverlay' },
					'#ui-show-error-message': {
						click: function () {
							Y.one('#ui-error-message').setStyle('display', '');
							Y.one('#ui-show-error-message').setStyle('display', 'none');
						}
					},
					'#ui-hide-error-message': {
						click: function () {
							Y.one('#ui-error-message').setStyle('display', 'none');
							Y.one('#ui-show-error-message').setStyle('display', '');
						}
					}
				},
				// don't add escape listeners and buttons
				notEscapable: false
			}
		},

		events: {
			'#ui-open-invoiceaddress': {
				click: function (e) {
					this._loadView(e, 'addressForm');
				}
			},
			'#ui-open-paymentmethod': {
				click: function (e) {
					this._loadView(e, 'paymentMethodForm');
				}
			},
			'#ui-choose-payment-sdd_buy': {
				click: function (e) {
					this._choosePayment(e, 'sdd_buy');
				},
				mouseover: function (e) {
					this._updatePaymentInfo(e, 'sdd_buy');
				}
			},
			'#ui-choose-payment-paypal': {
				click: function (e) {
					this._choosePayment(e, 'paypal');
				},
				mouseover: function (e) {
					this._updatePaymentInfo(e, 'paypal');
				}
			},
			'#ui-choose-payment-cc_buy': {
				click: function (e) {
					this._choosePayment(e, 'cc_buy');
				},
				mouseover: function (e) {
					this._updatePaymentInfo(e, 'cc_buy');
				}
			},
			'#ui-set-address-0': {
				click: function (e) {
					this._setAddress(e, 0);
				}
			},
			'#ui-set-address-1': {
				click: function (e) {
					this._setAddress(e, 1);
				}
			},
			'#ui-update-address': { click: '_updateAddress' },
			'#ui-update-payment': { click: '_updatePayment' },
			'#ui-start-checkout': { click: '_checkout' },
			'#ui-update-token': { click: '_updateToken' },
			'#ui-cancel': {
				click: function (e) {
					this._loadView(e, 'overview');
				}
			},
			'#ui-cancel-checkout': {
				click: function (e) {
					this.fire('open', {
						link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')
					});
				}
			},
			'#ui-conditions-checkbox': {
				click: function () {
					this._handleCheckboxClick();
				}
			},
			'#ui-datasecurity-checkbox': {
				click: function () {
					this._handleCheckboxClick();
				}
			}
		},

		initializer: function () {
			Y.all('.btn-group-fused').setStyle('visibility','hidden');
		},

		destructor: function () {
			var instance = this;

			while (instance._handler.length > 0) {
				instance._handler.pop().detach();
			}

			// last thing to do is to display the navigation button group again. (next, back, calculate)
			Y.all('.btn-group-fused').setStyle('visibility', '');
		},

		_handleCheckboxClick: function () {
			this._setCheckboxErrors();
			this._saveCheckboxState();
		},

		_setCheckboxErrors: function () {
			var c = this.get('container');

			if (c.one('#ui-datasecurity-checkbox').get('checked') === true) {
				c.one('#ui-case-editor-checkout-continue-accept-terms-conditions-error').setStyle('display', 'none');
			}
			if (c.one('#ui-datasecurity-checkbox').get('checked') === true) {
				c.one('#ui-case-editor-checkout-continue-accept-terms-datasecurity-error').setStyle('display', 'none');
			}
		},

		_saveCheckboxState: function () {
			this._checkoutConditionsState = this.get('container').one('#ui-conditions-checkbox').get('checked');
			this._checkoutDataSecState = this.get('container').one('#ui-datasecurity-checkbox').get('checked');
		},

		_load: function () {
			var info = this.get('checkoutInfo');
			info.load(Y.bind(function () {
				this.showView("overview", { checkoutInfo: info });
			}, this));
		},

		_loadView: function (e, view) {
			var info = this.get('checkoutInfo');
			if (view === null) {
				view = 'overview';
			}

			info.load(Y.bind(function () {
					this.showView(view, { checkoutInfo: info });
			}, this));
		},

		_updateAddress: function () {
			var that = this;
			var info = that.get('checkoutInfo');
			var addressForm = that.get('activeView').addressForm;
			var requiredFieldNames = [
				'firstname',
				'lastname',
				'street',
				'zip',
				'city',
				'countrycode',
			];

			var invalid = false;
			for (var i = 0; i < requiredFieldNames.length; i++) {
				var requiredFieldName = requiredFieldNames[i];
				var requiredFieldErrorMessage = Y.one('#ui-' + requiredFieldName + '-field-error-message');
				var requiredField = addressForm.getField(requiredFieldName);
				var requiredFieldValue = requiredField.get('value').trim();
				if (requiredFieldName === 'countrycode' ? requiredFieldValue === 'Deutschland' : requiredFieldValue) {
					requiredFieldErrorMessage.setStyle('display', 'none');
				} else {
					requiredFieldErrorMessage.setStyle('display', '');
					invalid = true;
				}
			}

			if (!invalid) {
				info.setAttrs({
					address: {
						type:        'Billing',
						firstName:   addressForm.getField('firstname').get('value').trim(),
						lastName:    addressForm.getField('lastname').get('value').trim(),
						street:      addressForm.getField('street').get('value').trim(),
						postalCode:  addressForm.getField('zip').get('value').trim(),
						city:        addressForm.getField('city').get('value').trim(),
						companyName: addressForm.getField('company').get('value').trim(),
						countryCode: addressForm.getField('countrycode').get('value').trim()
					}
				});
				info.save(function () {
					if (info._getAttr('validBillingAddress')) {
						that.showView('overview', { checkoutInfo: info });
					} else {
						that.showView('addressForm', { checkoutInfo: info });
					}
				});
			}
		},

		_setAddress: function (e, index) {
			var that = this,
				info = that.get('checkoutInfo'),
				altAddress = info._getAttr('alternativeAddresses')[index];
			info.setAttrs({
				address: {
					type:        'Billing',
					firstName:   altAddress.firstName,
					lastName:    altAddress.lastName,
					street:      altAddress.street,
					postalCode:  altAddress.postalCode,
					city:        altAddress.city,
					companyName: altAddress.companyName,
					countryCode: altAddress.countryCode
				}
			});
			info.save(function () {
				that.showView('overview', { checkoutInfo: info });
			});
		},

		_choosePayment: function (e, method) {
			var that = this,
				container = that.get('container'),
				paymentMethodForm = that.get('activeView').paymentMethodForm;

            // ONSE-12434 check if method is enabled
            if (Y._currentFilingState.paymentMethodCheckEnabled) {
				if (!Y._currentFilingState.paymentMethods.includes(method)) {
                    console.log("Skipping payment method " + method);

                    alert("Diese Zahlungsart steht momentan nicht zur Verfügung.\n\n"+
						  "Bitte wählen Sie eine andere Zahlungsart oder probieren Sie es später erneut.\n");

                    return;
                }
            }

			paymentMethodForm.getField('paymentMethod').set('value', method);
			container.all('.ui-choose-payment-btn').removeClass('active');
			container.one('#ui-choose-payment-' + method).addClass('active');
		},

		_updatePaymentInfo: function (e, method) {
			var info_paypal = '<h3>PayPal</h3>' +
					'<p>PayPal gehört zu den bekanntesten Zahlungsdienstleistern im Internet. PayPal steht für eine sichere und schnelle Online-Zahlung, nicht zuletzt dank des kostenlosen PayPal-Käuferschutzes in unbegrenzter Höhe.</p>' +
					'<p>Sie können Ihr PayPal-Konto mit Ihrem Bankkonto oder Ihrer Kreditkarte verknüpfen, so dass Ihre sensiblen Bankdaten nur bei PayPal hinterlegt sind. Bei der Benutzung von PayPal kommen wir mit Ihren Kontodaten oder Kreditkarteninformationen nicht in Kontakt.</p>',
				info_sdd = '<h3>SEPA-Lastschrift</h3>' +
					'<p>Die SEPA-Lastschrift ist das bekannteste Zahlungsmittel. Geben Sie im Bestellprozess Ihre IBAN und BIC an, und der Rechnungsbetrag wird einmalig von Ihrem Konto abgebucht.</p>',
				info_cc = '<h3>Kreditkarte</h3>' +
					'<p>Wir akzeptieren VISA und MasterCard. Geben Sie im Laufe des Bestellprozesses Ihre Kreditkartendaten ein.</p>' +
					'<p>Wir unterstützen nur Karten, die das 3D-Secure-Verfahren (Verified by Visa oder MasterCard® SecureCode™) umsetzen. Hierbei handelt es sich um ein zusätzliches Sicherheitsmerkmal bei Online-Kreditkartentransaktionen.</p>',

				info_text = '';
			switch (method) {
				case 'paypal':
					info_text = info_paypal;
					break;
				case 'sdd_buy':
					info_text = info_sdd;
					break;
				case 'cc_buy':
					info_text = info_cc;
					break;
			}

			Y.one('#ui-case-editor-checkout-edit-payment-method-details').setHTML(info_text);
			if (Y.UA.touchEnabled > 0) {
				// touch devices send a mouseover on the first touch, we want the 'click' behaviour already on that
				this._choosePayment(e, method);
			}
		},

		_updatePayment: function () {
			var that = this,
				info = this.get('checkoutInfo'),
				paymentMethodForm = that.get('activeView').paymentMethodForm,
				payment = paymentMethodForm.getField('paymentMethod').get('value');

			if (payment == '' || payment == null) {
				Y.one('#ui-case-editor-checkout-edit-payment-method-main-error-message').setStyle('display', '');
			} else {
				info.setAttrs({
					payment: payment,
					checkoutConditionsState: this._checkoutConditionsState,
					checkoutDataSecState: this._checkoutDataSecState
				});
				info.save(function () {
					that.showView('overview', { checkoutInfo: info });
				});
			}
		},

		_updateToken: function () {
			var that = this,
				info = this.get('checkoutInfo'),
				tokenForm = that.get('activeView').tokenForm,
				cfg = {
					method: 'POST',
					data: 'tokenCode=' + tokenForm.getField('tokenCode').get('value'),
					on: {
						success: function (id, o) {
							var that = this;
							info.load(Y.bind(function() {
								that.get('activeView').set('checkoutInfo', info);
							}, that));
						},
						failure: function () {
							this.showOverlay('checkout-error',null);
							Y.log("Request failed or was aborted in _checkout",'warn','Checkout');
						}
					},
					context: this
				};
			Y.io("api/checkout/validateToken/" + this.get('checkoutInfo').get('id'), cfg);
		},

		_checkout: function () {
			var that = this,
				info = that.get('checkoutInfo'),
				container = this.get('container'),
				datasecurityAccepted, termsAccepted,
			cfg = {
				method: 'GET',
				on: {
					success: function (id, o) {
						var iframeUrl = Y.JSON.parse(o.responseText).iframeUrl;
						if (iframeUrl == null) {
							iframeUrl = Y.JSON.parse(o.responseText).redirectUrl;
						}
						info.load(Y.bind(function () {
							that.showView("payment", { checkoutInfo: info, iframeUrl: iframeUrl });
						}, that));
						that.enableCheckoutButton();
					},
					failure: function (id, o) {
						var buildMessage = function (message) {
							return '<div class="app-alert app-alert-problem">' +
								'<i class="fa fa-exclamation-circle"></i>' +
								'<div>' +
								'<h1>Es ist ein Fehler aufgetreten.</h1>' +
								message +
								'</div>' +
								'</div>';
						}

						Y.log("Request failed or was aborted in _checkout", 'warn', 'Checkout');

						var msg;

						var clientError = o.status === 400;
						if (clientError) {
							try {
								var response = Y.JSON.parse(o.responseText);
								var error = response.error;
								if (error) {
									msg = buildMessage(error);
								}
							} catch (e) {
								Y.log("_checkout error response could not be parsed.", 'error', 'Checkout');
							}
						}

						// This is wrong:
						// that.enableCheckoutButton();
						// Problem is, a failed request to check out can still succeed on the server, so that the user has
						// credit. So we have to check for that before we ask the user to buy again.
						//this.showOverlay('checkout-error',null);

						if (!msg) {
							msg = buildMessage('<p>Bitte übermitteln Sie Ihre Daten erneut.</p>' +
								'<p class="app-small-text">Sollte der Vorgang weiterhin fehlschlagen, informieren Sie bitte unseren Support über das Supportformular.' +
								' Klicken Sie dazu bitte auf den »Hilfe«-Button neben dem Suchfeld. Wir helfen Ihnen gerne weiter.</p>');
						}

						that.fire('alert', { msg: msg });

						if (!clientError) {
							// We redirect the user to the beginning of the filing process.
							// So another check for credit is triggered, and in the case that the user has credits, this will hopefully show.
							// TODO maybe add a small timeout here, to make the credit check more safe
							this.fire('open', { link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link') });
						}
					}
				},
				context: this
			};

			datasecurityAccepted = container.one('#ui-datasecurity-checkbox').get('checked') == true;
			termsAccepted = container.one('#ui-conditions-checkbox').get('checked') == true;

			if (termsAccepted && datasecurityAccepted && info._getAttr('validBillingAddress') && (info._getAttr('validPayment') || info._getAttr('product.priceCoveredByToken'))) {
				that.disableCheckoutButton();
				Y.io("api/checkout/doit/" + this.get('checkoutInfo').get('id'), cfg);
			} else {
				if (!termsAccepted) {
					container.one('#ui-case-editor-checkout-continue-accept-terms-conditions-error').setStyle('display', '');
				}
				if (!datasecurityAccepted) {
					container.one('#ui-case-editor-checkout-continue-accept-terms-datasecurity-error').setStyle('display', '');
				}
			}
		},

		enableCheckoutButton: function () {
			this.get('container').one('#ui-start-checkout').removeClass('btn-disabled').removeAttribute('disabled');
		},

		disableCheckoutButton: function () {
			this.get('container').one('#ui-start-checkout').addClass('btn-disabled').setAttribute('disabled','disabled');
		},

		_closeOverlay: function (e, payload) {
			// only close on enter
			if (e.keyCode && 13 !== e.keyCode) {
				return;
			}
			e.halt();
			payload.overlay.hide();
			// optional callback.
			payload.callback && payload.callback(payload);
		},

		/**
		 * For the time being we have to disable any 'next' navigation here.
		 * We simply overwrite our parents method and do nothing.
		 */
		handleNext: function () {
			// nothing to do. There is no 'next' in our case.
		},

		// this is our new 'openArea'
		handleNavigation: function (navInfo) {
			var info = this.get('checkoutInfo'),
				paymentOutcome = navInfo.paymentOutcome;
			Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppCheckout');
			// we will do an ajax request in a second, so we should indicate that we are waiting for its outcome.
//		    this.fire('waiting', {msg:'Ihr Fall wird abschließend geprüft und erneut berechnet…'});
			if (paymentOutcome === 'canceled') {
				// FIXME: calling showView() does not remove the /canceled from the url
				// as all views are opened like this, it keeps there and prevents the /canceled link from working the next time
//			    this.showView('paymentMethodForm', {checkoutInfo:info});
				// for the time being we will switch back to "filing" to prevent the error three lines up.
				this.fire('open', {
					link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')
				});
			} else {
				this._load();
			}
		}
	}, {
		ATTRS: {
			container: {
				valueFn: function () {
					return Y.Node.create('<div id="case-editor-app-checkout"/>');
				}
			},
			view: {
			},
			checkoutInfo: {
				valueFn: function () {
					return new Y.CheckoutInfo({ id: null });
				}
			}
		}
	});
}, '1.0.0', {
	requires: [
		'app',
		'base-build',
		'case-editor-app-base',
		'io-base',
		'io-form',
		'json-parse',
		'node',
		'smst-lang',
		'smart-form',
		'event-valuechange',
		'event'
	]
});