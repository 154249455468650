// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-manager-views', function (Y) {

    "use strict";

    Y.CaseManagerHeaderView = Y.Base.create('CaseManagerHeaderView', Y.HandlebarsView, [], {

        // Provide a template that will be used to render the view. The template can
        // be anything we want.
        template: 'case-manager-header',

        events: {
            '.ui-case-new-empty': {
                click: function (e) {
                    this.fireCreateEvent(e, 'newCaseBlank');
                }
            },
            '.ui-case-new-from-template': {
                click: function (e) {
                    this.fireCreateEvent(e, 'newCaseFromTemplate');
                }
            },
            '.ui-case-new-import': {
                click: function (e) {
                    this.fireCreateEvent(e, 'newCaseImport');
                }
            }
        },

        // The initializer function will run when a view is instantiated.
        initializer: function () {
            // we will fire events when ever a button is clicked.
            this.publish('newCaseBlank');
            this.publish('newCaseFromTemplate');
            this.publish('newCaseFromLastYear');
            this.publish('newCaseImport');
        },

        // --- event handler ---------------------------------------------------------------------------------------

        fireCreateEvent: function (e, eventToFire) {
            // the event was a click event so we have to stop it here.
            e.halt();
            // all we have to do here is to fire an event.
            // the app will take care of the heavy lifting.
            this.fire(eventToFire);
        }


    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {
            // Override the default container attribute.
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="cases-manager-header" />');
                }
            }
        }
    });


    Y.CaseTilesView = Y.Base.create('CaseTilesView', Y.HandlebarsView, [], {

        // Provide a template that will be used to render the view. The template can
        // be anything we want.
        template: 'case-manager-tiles',

        // This template is using partials. We have to configure them as well.
        partials: ['case-manager-tile', 'case-status'],

        // Attach DOM events for the view. The `events` object is a mapping of
        // selectors to an object containing one or more events to attach to the
        // node(s) matching each selector.
        events: {
            '.ui-case-open': {
                click: function (e) {
                    this.fireEvent(e, 'openCase', false)
                }
            },
            '.ui-case-delete': {
                click: function (e) {
                    this.fireEvent(e, 'deleteCase')
                }
            },
            '.ui-case-rename': {
                click: function (e) {
                    this.fireEvent(e, 'renameCase')
                }
            },
            '.ui-case-export': {
                click: function (e) {
                    this.fireEvent(e, 'exportCase')
                }
            },
            '.ui-case-import': {
                click: function (e) {
                    this.fireEvent(e, 'importCase')
                }
            },
            '.ui-qs-import-overlay-cta': {
                click: function (e) {
                    this.fire('importQuickSteuerCase')
                }
            },
            '.ui-case-lock': {
                click: function (e) {
                    this.fireEvent(e, 'lockCase')
                }
            },
            '.ui-case-unlock': {
                click: function (e) {
                    this.fireEvent(e, 'unlockCase')
                }
            },
            '.ui-case-operations-container': {
                click: function (e) {
                    this.toggleCaseMenu(e)
                }
            },
            // as this one here will trigger a full render it has to come last. Otherwise those other click handlers
            // wouldn't have a chance to get anything done.
            '.ui-case': {click: 'markActive'}
        },

        // The initializer function will run when a view is instantiated. This is a
        // good time to subscribe to change events on a model instance.
        initializer: function () {
            Y.once('smartsteuer:ready', function (config) {
                this.set('config', config)
            }, this);

            var list = this.get('modelList');

            // Re-render this view when a model is added to or removed from the model
            // list.
            list.after(['add', 'remove', 'reset'], this.render, this);
            // We'll also re-render the view whenever the data of one of the models in
            // the list changes.
            list.after('*:change', this.render, this);

            // when one of our listed cases is opened/changed/deleted we will shout that out.
            this.publish('openCase', {preventable: false});
            this.publish('deleteCase', {preventable: false});
            this.publish('renameCase', {preventable: false});
            this.publish('exportCase', {preventable: false});
            this.publish('importCase', {preventable: false});
            this.publish('importQuickSteuerCase', {preventable: false});
            this.publish('lockCase', {preventable: false});
            this.publish('unlockCase', {preventable: false});
        },

        // we have to implement our own render method as the one provided by HandlebarsView only works with single model
        // instances.
        render: function () {
            var that = this,
                data = that.get('modelList').map(function (it) {
                    var result = it.toJSON();
                    // Add `clientId` to the data, this is ignored by `toJSON()`. This
                    // will be used by the template and is an easy way to regain access
                    // to the associated Repo model.
                    result.clientId = it.get('clientId');
                    return result;
                });

            var ret = that._render({
                sessions: data,
                showEmptyMessage: true,
                quickSteuerUser: that.get('config').quickSteuerUser
            });

            if (Y.one('#ui-is-mobile').get('value') == 'true') {
                this.fire('caseManagerRendered');
            }

            return ret;
        },

        fireEvent: function (e, eventName, preventDefault) {
            // Because we've rendered our list of cases with the `clientId` as the
            // `id` attribute for each case node, we can use this client id to
            // retrieve the Case model instance from the CaseList.
            var caseContainer = e.currentTarget.ancestor('.ui-case'),
                caze = this.get('modelList').getByClientId(caseContainer.get('id'));

            // the event was a click event so we have to prevent its default action here (if the link is not meant to be evaluated).
            // Important! Don't stop it here as we want to mark it as active in an extra listener which has to
            // receive the same event!
            if (preventDefault) {
                e.preventDefault();
            }

            caze.set('showOptions', false);

            if (caze) {
                this.fire(eventName, {'case': caze});
            }
        },

        markActive: function (e) {
            // Any change on a model will trigger this view to re-render.
            // Setting the 'active' attribute of a case will make it's caseList deactivate any other case
            // so when the view is rendered only one case will be marked as active at any given time.
            var caze = this.get('modelList').getByClientId(e.currentTarget.get('id'));
            if (caze) {
                caze.set('active', true);
            }
        },

        toggleCaseMenu: function (e) {
            var caseContainer = e.currentTarget.ancestor('.ui-case'),
                caze = this.get('modelList').getByClientId(caseContainer.get('id')),
                active = !caze.get('showOptions');

            this.get('modelList').map(function (it) {
                it.set('showOptions', false);
            });

            caze.set('showOptions', active);
        }
    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {
            // Override the default container attribute.
            container: {
                valueFn: function () {
                    return Y.Node.create('<ul id="cases-manager-caselist"/>');
                }
            }
        }
    });


    Y.CaseLegacyTilesView = Y.Base.create('CaseLegacyTilesView', Y.CaseTilesView, [], {
        events: {
            '.ui-case-migrate': {
                click: function (e) {
                    this.fireEvent(e, 'migrateCase')
                }
            },
            '.ui-case-open': {
                click: function (e) {
                    this.fireEvent(e, 'editLegacyCase', true)
                }
            }
        },

        initializer: function () {
            this.publish('migrateCase', {preventable: false});
            this.publish('editLegacyCase');
        },

        render: function () {
            var that = this,
                data = that.get('modelList').map(function (it) {
                    var result = it.toJSON();
                    // Add `clientId` to the data, this is ignored by `toJSON()`. This
                    // will be used by the template and is an easy way to regain access
                    // to the associated Repo model.
                    result.clientId = it.get('clientId');
                    result.vz = that.get('config').vz
                    return result;
                });
            Y.log('Refreshing view...', null, 'CaseListView');
            return that._render({sessions: data});
        },

        fireEvent: function (e, eventName, preventDefault) {
            // Because we've rendered our list of cases with the `clientId` as the
            // `id` attribute for each case node, we can use this client id to
            // retrieve the Case model instance from the CaseList.
            var caseContainer = e.currentTarget.ancestor('.ui-case'),
                caze = this.get('modelList').getByClientId(caseContainer.get('id'));

            // the event was a click event so we have to prevent its default action here (if the link is not meant to be evaluated).
            // Important! Don't stop it here as we want to mark it as active in an extra listener which has to
            // receive the same event!
            if (preventDefault) {
                e.preventDefault();
            }

            if (caze) {
                this.fire(eventName, {'case': caze});
            }
        }
    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {
            // Override the default container attribute.
            container: {
                valueFn: function () {
                    return Y.Node.create('<ul id="cases-manager-case-legacy-list"/>');
                }
            }
        }
    });


    Y.CaseManagerTilesView = Y.Base.create('CaseManagerTilesView', Y.View, [], {

        initializer: function () {
            // which it delegates rendering and stitches together the resulting UI.
            // Sub-views are created to render the `User` and `RepoList`.
            this.caseTilesView = new Y.CaseTilesView({modelList: this.get('caseList')});
            this.caseLegacyTilesView = new Y.CaseLegacyTilesView({modelList: this.get('legacyList')});

            // This will cause the sub-views' custom events to bubble up to here.
            this.caseTilesView.addTarget(this);
            this.caseLegacyTilesView.addTarget(this);
        },

        // This destructor is specified so this view's sub-views can be properly
        // destroyed and cleaned up.
        destructor: function () {
            this.caseTilesView.removeTarget(this);
            this.caseLegacyTilesView.removeTarget(this);

            this.caseTilesView.destroy();
            this.caseLegacyTilesView.destroy();

            delete this.caseTilesView;
            delete this.caseLegacyTilesView;
        },


        render: function () {
            // A document fragment is created to hold the resulting HTML created
            // from rendering the two sub-views.
            var content = Y.one(Y.config.doc.createDocumentFragment());

            // This renders each of the two sub-views into the document fragment,
            // then sets the fragment as the contents of this view's container.
            content.append(this.caseTilesView.render().get('container'));
            content.append(this.caseLegacyTilesView.render().get('container'));

            this.get('container').setHTML(content);

            // Stickin' to best practices and returning this view
            return this;
        }
    }, {
        // Specify attributes and static properties for your View here.
        ATTRS: {
            // Override the default container attribute.
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="cases-manager-tiles" />');
                }
            }
        }
    });

}, '1.0.0', {
    requires: [
        'base-build',
        'handlebars-view'
    ]
});