YUI.add('smst-heartbeat', function(Y) {
	"use strict";

	Y.namespace('smst').Heartbeat = Y.Base.create('Heartbeat', Y.Base, [], {

		_handler: [],

		_pingTimer: null,

		_pinger: null,

		initializer : function() {
			this._initShopPing();
		},

		destructor : function() {
			this._cancel();
		},
		
		// --- shop ping -------------------------------------------------------------------------------------

		_initShopPing: function() {
			var that = this;
			that._pinger = new Y.JSONPRequest(that.get('shopPingUrl'), {
				context: that,
				on: {
					success: that._pong,
					failure: that._error,
					timeout: that._error
				},
				timeout: 3000
			});
			// send the first ping.
			that._ping();
			that._pingTimer = Y.later( 300000, that, that._ping, null, true );  // once every 5 minutes
		},

		_ping: function() {
			Y.log("ping", null, 'Heartbeat');
			this._pinger.send();
		},

		_pong: function(res) {
			if( null == res.user ) {
				Y.log('User logged out at the shop.', 'WARN', 'Heartbeat');
				this._cancel();
			} else {
				Y.log('pong', null, 'Heartbeat');
			}
		},

		_error: function() {
			Y.log('Shop unreachable!', 'WARN', 'Heartbeat');
			this._cancel();
		},

		_cancel : function() {
			Y.log('Shutting down ping…', 'DEBUG', 'Heartbeat');
			if( this._pingTimer ) {
				this._pingTimer.cancel();
			}
		}



	}, {
		ATTRS:{
			'shopPingUrl' : {
				writeOnce : 'initOnly'
			}
		}
	});

}, '1.0.0', { requires:[
	'base-build',
	'jsonp'
]});