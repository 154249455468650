// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2-documents', function(Y) {
    "use strict";

    Y.CaseEditorAppFiling2DocumentsPrintView = Y.Base.create('CaseEditorAppFiling2DocumentsPrintView',
        Y.HandlebarsView, [], {

        template : 'case-editor-filing2-documents-print',
        partials : ['case-editor-filing2-notSentWarning', 'case-editor-filing2-elsterNotAvailable'],
        param : 'printed',

        initializer: function() {
            this.get('container').delegate('click',this._handleClick,'input',this);
        },

	    _handleClick : function() {
            var cont = this.get('container');
            var tmpNode = cont.one('input');

            var checked = tmpNode.get('checked');
            var button = cont.one('#ui-go-to-filing2-send');
            var pathFilled = true;

            if(!button) {
                button = cont.one('#ui-go-to-filing2-final');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
            }

            if(!button) {
                button = cont.one('#ui-go-to-filing2-smartpay');
                if(!this.get('filingState').documentsPrinted) pathFilled = false;
            }

		    if (checked && pathFilled) {
                var data = {}
                data[this.param] = checked;
                Y.io('elster/saveInterviewSessionParam', {
                    method: 'POST',
                    data: data
                });

			    button.removeClass('btn-disabled');
		    } else {
			    button.addClass('btn-disabled');
		    }
	    }
    }, {
        ATTRS: {
            filingState: {},
            hasEuer: {
                getter: function () {
                    return Y._currentState && Y._currentState.hasEuer;
                }
            }
        }
    });

    Y.CaseEditorAppFiling2DocumentsSendView = Y.Base.create('CaseEditorAppFiling2DocumentsSendView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
        // we extend the print view (above) and just use another template
        template: 'case-editor-filing2-documents-send',
        param : 'sent'
    });


    Y.CaseEditorAppFiling2Documents = Y.Base.create('CaseEditorAppFiling2Documents',
        Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
	    views: {
            print : {type: Y.CaseEditorAppFiling2DocumentsPrintView},
            send : {type: Y.CaseEditorAppFiling2DocumentsSendView}
	    },

        getLink : function (name) {
             return Y.smst.CaseEditor.SECTIONS.getByName(name).get('link');
        },


        events: {
            '#ui-go-to-filing2-print': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink('filing2-process-documents/print')});
            }},
            '#ui-go-to-filing2-send': {click: function (e) {
                var that = this;

                if(that.isDisabled(e)) {
                    return;
                }

                //skip
                Y.io('elster/saveInterviewSessionParam', {
                    method: 'POST',
                    context: this,
                    on: {
                        success: function () {
                            that.fire('open', {link: this.getLink("filing2-process-documents/send")});
                        }
                    },
                    data: {
                        printed: true,
                        sent: false
                    }
                });

            }},
            '#ui-go-to-filing2-smartpay': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink("filing2-process-smartpay")});
            }},
            '#ui-go-to-filing2-final': {click: function (e) {
                this.isDisabled(e) || this.fire('open', {link:this.getLink("filing2-process-final")});
            }}
        },

        isDisabled : function(e) {
            if(e.currentTarget.hasClass('btn-disabled')) {
                this.fire('alert', {msg: "Bitte führen Sie alle Schritte in der angegebenen Reihenfolge durch und setzen Sie die entsprechenden Haken."});
                return true;
            }
            else {
                return false;
            }
        },

        overlays: {
        },

        handleNavigation : function(navInfo) {
            var app = this;
            var formId = navInfo.formId;
            var data = {
                filingState : app.get('filingState'),
                filingLink :app.getLink('filing2-process-filing'),
                sendLink : app.getLink('filing2-process-documents/send'),
                mayShowSmartPay : Y._currentState.mayShowSmartPay
            };

            app.fire('removeWaiting');
            app.fire('waiting', {msg: 'Bitte warten...'});

            if (formId === 'print') {
                app.showView('print', data);
            } else {
                app.showView('send', data);
            }
            app.fire('removeWaiting');
	    }
    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    return Y.Node.create('<div id="case-filing-documents"/>');
                }
            },
	        filingState : {}
        }
    });


}, '1.0.0', {
    requires:[
        'app',
        'base-build',
	    'smart-handlebars-templates',
        'case-editor-app-base',
        'io-base',
		'json-parse',
        'node',
        'smst-lang'
    ]});
